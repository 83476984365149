import { Stage } from '@efishery/sdk-core';

export const APP_NAME = import.meta.env.VITE_APP_NAME ?? 'dibalik-supply';
export const APP_VERSION = import.meta.env.VITE_APP_VERSION ?? 'preview';
export const APP_COMMIT = import.meta.env.VITE_APP_COMMIT ?? 'xxx';
export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT ?? 'preview';
export const ENABLE_TOGGLE_DARK_MODE = (import.meta.env.VITE_ENABLE_TOGGLE_DARK_MODE ?? 'FALSE') === 'TRUE';
export const APP_PR_ID = import.meta.env.VITE_APP_PR_ID ?? 'xxx';
export const APP_VAULT_ADDRESS = import.meta.env.VITE_VAULT_ADDRESS ?? 'localhost';
export const FUND_OPS_MGR_EMAIL = import.meta.env.VITE_FUND_OPS_MGR_EMAIL ?? '';

// auth v2
export const AUTH_V2_STAGE = Stage[(import.meta.env.VITE_AUTH_V2_STAGE ?? 'stg') as keyof typeof Stage];
export const AUTH_V2_CLIENT_ID = import.meta.env.VITE_AUTH_V2_CLIENT_ID ?? '';
export const AUTH_V2_DOMAIN = import.meta.env.VITE_AUTH_V2_DOMAIN ?? '';
export const AUTH_REDIRECT_URL = import.meta.env.VITE_AUTH_REDIRECT_URL ?? '';
export const AUTH_SERVICE_URL = import.meta.env.VITE_AUTH_SERVICE_BASE_URL ?? '';
// end of auth v2

// Sentry Configuration
export const APP_SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN ?? '';
export const APP_SENTRY_SAMPLE_RATE = import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE ?? '';
export const APP_SENTRY_RELEASE = import.meta.env.VITE_APP_SENTRY_RELEASE ?? 'master';
// end of Sentry Configuration

// SKU Management
export const SKU_SERVICE_URL = import.meta.env.VITE_SKU_SERVICE_URL ?? '';
export const SKU_SOP_GUIDE_URL = import.meta.env.VITE_SKU_SOP_GUIDE_URL ?? '';
export const SKU_SHOW_EMPTY_THUMBNAIL = import.meta.env.VITE_SKU_SHOW_EMPTY_THUMBNAIL ?? '';
// end of SKU Management

// WMS Service
export const WMS_SERVICE_URL = import.meta.env.VITE_WMS_SERVICE_URL ?? '';
// end of WMS Service

// OMS Service
export const OMS_SERVICE_URL = import.meta.env.VITE_OMS_SERVICE_URL ?? '';
// end of OMS Service

// File Manager Service
export const FILE_MANAGER_SERVICE_URL = import.meta.env.VITE_FILE_MANAGER_SERVICE_URL ?? '';
export const IMAGE_PRE_PROCESS_URL = import.meta.env.VITE_IMAGE_PRE_PROCESS_URL ?? '';

// PAYMENT Service
export const PAYMENT_SERVICE_URL = import.meta.env.VITE_PAYMENT_SERVICE_URL ?? '';
// end of PAYMENT Service

// VENDOR Service
export const VENDOR_SERVICE_URL = import.meta.env.VITE_VENDOR_SERVICE_URL ?? '';
// end of VENDOR Service

// CORE Support Service
export const CORE_SUPPORT_SERVICE_URL = import.meta.env.VITE_CORE_SUPPORT_SERVICE_URL ?? '';
// end of CORE Support Service

// PROCUREMENT SERVICE
export const PROCUREMENT_SERVICE_URL = import.meta.env.VITE_PROCUREMENT_SERVICE_URL ?? '';
// end of PROCUREMENT Service

// FLAGGING Support Service
export const FLAGGING_SERVICE_URL = import.meta.env.VITE_FLAGGING_SERVICE_URL ?? '';
// end of FLAGGING Support Service

// MAINTENANCE Flag
export const ENABLE_FEATURE_MAINTENANCE = String(import.meta.env.VITE_ENABLE_FEATURE_MAINTENANCE);
// end of maintenance flag

// FLAGGING First Mile
export const ENABLE_FEATURE_FIRST_MILE = Number(import.meta.env.VITE_ENABLE_FEATURE_FIRST_MILE_FLAGR_ID) ?? 0;
export const ENABLE_FEATURE_PO_DELIVERY_REQUEST =
  Number(import.meta.env.VITE_ENABLE_FEATURE_PO_DELIVERY_REQUEST_FLAGR_ID) ?? 0;
// end of First Mile

export const DISABLE_FEATURE_ACTIVE_VENDOR_FORM_VALIDATION =
  Number(import.meta.env.VITE_DISABLE_FEATURE_ACTIVE_VENDOR_FORM_VALIDATION_FLAGR_ID) ?? 0;

// FLAGR UNTUK FEATURE YG BELOM NAIK KE PROD
export const ENABLE_FEATURE_PO_AP = Number(import.meta.env.VITE_ENABLE_FEATURE_PO_AP_FLAGR_ID) ?? 0;

// To get list users like email, etc
export const AUTH_USER_SERVICE_URL = import.meta.env.VITE_AUTH_USER_SERVICE_URL ?? '';

// FLAGGING New Area Adoption
export const ENABLE_FEATURE_NEW_AREA_VALIDATION =
  Number(import.meta.env.VITE_ENABLE_FEATURE_NEW_AREA_VALIDATION_FLAGR_ID) ?? 0;

// FLAGGING Filter Point Active
export const ENABLE_FEATURE_FILTER_POINT_ACTIVE =
  Number(import.meta.env.VITE_ENABLE_FEATURE_FILTER_POINT_ACTIVE_FLAGR_ID) ?? 0;

// FLAGGING VB
export const ENABLE_FEATURE_VB_ODOO_VER_PAYMENT_TERM =
  Number(import.meta.env.VITE_ENABLE_FEATURE_VB_ODOO_VER_PAYMENT_TERM) ?? 0;

export const IS_TESTING_ENV = process.env.NODE_ENV === 'test';
